<template>
  <div class="container">
    <h1>The Benefits of Intermittent Fasting: What Science Says</h1>
    <p>
      Intermittent fasting (IF) has surged in popularity as a lifestyle choice
      that may offer numerous health benefits. Rather than focusing on what you
      eat, IF emphasizes when you eat, involving regular periods of eating and
      fasting. This article delves into the scientifically supported advantages
      of intermittent fasting and highlights essential considerations before you
      begin.
    </p>
    <h2>Benefits of Intermittent Fasting</h2>

    <p>1. Weight Loss and Improved Metabolism</p>
    <p>
      Intermittent fasting can aid in weight loss by reducing overall calorie
      intake and boosting metabolic rate. Fasting periods increase levels of
      norepinephrine, a hormone that enhances fat burning, leading to greater
      energy expenditure.
    </p>
    <p>
      Source:
      <a
        target="_blank"
        href="https://prsinstitute.org/downloads/related/health/Alternate-DayFastingandChronicDiseasePrevention.pdf"
        >Varady KA, Hellerstein MK. "Alternate-day fasting and chronic disease
        prevention: a review of human and animal trials." The American Journal
        of Clinical Nutrition (2007)</a
      >
    </p>
    <p>2. Enhanced Insulin Sensitivity</p>
    <p>
      By improving insulin sensitivity, intermittent fasting helps lower blood
      sugar levels, reducing the risk of type 2 diabetes. Fasting periods allow
      insulin levels to decrease, which facilitates fat burning.
    </p>
    <p>
      Source:
      <a
        target="_blank"
        href="https://www.translationalres.com/article/S1931-5244(14)00200-X/abstract"
        >Barnosky AR et al. "Intermittent fasting vs daily calorie restriction
        for type 2 diabetes prevention: a review of human findings."
        Translational Research (2014)</a
      >
    </p>
    <p>3. Cellular Repair and Autophagy</p>
    <p>
      Fasting triggers autophagy, a cellular repair process where cells remove
      dysfunctional components. This can protect against diseases like cancer
      and neurodegenerative disorders by eliminating damaged cells.
    </p>
    <p>
      Source:
      <a
        target="_blank"
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6402639/"
        >Levine B, Kroemer G. "Biological Functions of Autophagy Genes: A
        Disease Perspective." Cell (2019)</a
      >
    </p>
    <p>4. Reduction of Inflammation</p>
    <p>
      Intermittent fasting may reduce inflammation levels in the body. Chronic
      inflammation is linked to various health issues, including heart disease,
      diabetes, and certain cancers.
    </p>
    <p>
      Source:
      <a
        target="_blank"
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3946160/"
        >Longo VD, Mattson MP. "Fasting: Molecular Mechanisms and Clinical
        Applications." Cell Metabolism (2014)</a
      >
    </p>
    <p>5. Improved Heart Health</p>
    <p>
      By positively affecting blood pressure, cholesterol, and triglyceride
      levels, intermittent fasting can enhance heart health. These factors are
      crucial in reducing the risk of cardiovascular diseases.
    </p>
    <p>
      Source:
      <a
        target="_blank"
        href="https://pmc.ncbi.nlm.nih.gov/articles/PMC5990470/"
        >Sutton EF et al. "Early Time-Restricted Feeding Improves Insulin
        Sensitivity, Blood Pressure, and Oxidative Stress Even without Weight
        Loss in Men with Prediabetes." Cell Metabolism (2018)</a
      >
    </p>
    <p>6. Enhanced Brain Function</p>
    <p>
      Intermittent fasting may boost brain health by increasing the production
      of brain-derived neurotrophic factor (BDNF), a protein that supports
      neuron growth and protects against neurological diseases.
    </p>
    <p>
      Source:
      <a
        target="_blank"
        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4250148/"
        >Mattson MP et al. "Meal frequency and timing in health and disease."
        Proceedings of the National Academy of Sciences (2014)</a
      >
    </p>
    <h2>Should You Avoid Intermittent Fasting?</h2>
    <p>
      You should only start intermittent fasting when you are healthy, do not
      have a preexisting health condition, and are not pregnant.
    </p>
    <p>You should consult a doctor before starting IF if you:</p>
    <p>
      <ul>
        <li>- Have diabetes.</li>
        <li>- Have problems with blood sugar regulation.</li>
        <li>- Have low blood pressure.</li>
        <li>- Are taking medication.</li>
        <li>- Are underweight.</li>
        <li>- Have a history of eating disorders.</li>
        <li>- Are a woman trying to get pregnant.</li>
        <li>- Are a woman with a history of amenorrhea.</li>
        <li>- Are pregnant or breastfeeding.</li>
      </ul>
    </p>
    <p></p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    let type = this.$route.params.type;
    if (type === "app") {
      this.$store.commit("setIsApp", true);
    } else {
      this.$store.commit("setIsApp", false);
    }
  },
  mounted() {},
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 70%;
  height: 100%;
  // background-color: rgba(248, 248, 248, 1);
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 60px;

  p {
    text-align: left !important;
    margin-top: 7px;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h2,
  h3 {
    margin-top: 10px;
    text-align: left;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    width: 90%;
  }
}
</style>
